<template>
  <section>
    <b-row class="mb-1">
      <b-col
        cols="12"
        class="px-1"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>Perfis de Usuários</h4>
              </b-col>
              <b-col cols="4">
                <validation-observer ref="profileValidation">
                  <b-form
                    class="form-validate"
                  >
                    <b-form-group
                      label="Escolha o perfil"
                      label-for="profileName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nome de perfil"
                        rules="required"
                      >
                        <v-select
                          v-model="selected"
                          label="name"
                          :clearable="false"
                          :options="optionsProfileSelect"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>
              </b-col>
              <b-col cols="4">
                <b-media no-body>
                  <b-media-aside
                    class="mr-1 my-1"
                  >
                    <b-avatar
                      size="40"
                      variant="light-success"
                      style="border-radius: 0.5rem !important"
                    >
                      <feather-icon
                        size="20"
                        icon="UserIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bold mb-0">
                      {{ qtdUserByProfile }}
                    </h4>
                    <b-card-text class="font-weight-medium font-small-3 mb-0">
                      usuários ativos
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                cols="4"
                class="px-5 d-flex flex-column"
              >
                <b-button
                  v-if="$can('edit', 'configProfile')"
                  variant="outline-primary"
                  type="submit"
                  class="mb-1"
                  @click="deleteProfile"
                >
                  <span class="align-middle">Excluir perfil</span>
                </b-button>

                <b-button
                  v-if="$can('edit', 'configProfile')"
                  variant="primary"
                  @click="goToNewProfile"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Novo perfil</span>
                </b-button>

              </b-col>
            </b-row>

          </b-card>

        </b-overlay>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              class="px-3 py-1 d-flex align-items-center justify-content-between"
            >
              <h4>Escolhas as funcionalidades</h4>
              <b-button
                v-if="$can('edit', 'configProfile')"
                variant="primary"
                type="submit"
                @click.prevent="updateProfilePermissions"
              >
                <span class="align-middle">Salvar</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">Permissões</span>
                </b-card-title>
              </b-card-header>
              <b-overlay
                :show="isAPICallInProgress"
                rounded="sm"
              >
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="permissionsData"
                  :fields="tableColumns"
                  :sort-desc.sync="sortDesc"
                  :sort-by.sync="sortBy"
                >
                  <template #cell(featureName)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(edit)="data">
                    <b-form-checkbox
                      :checked="data.value"
                      :disabled="$can('edit', 'configProfile') === false"
                      @change="handleUpdatePermitions($event, data)"
                    />

                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      :checked="data.value"
                      :disabled="$can('edit', 'configProfile') === false"
                      @change="handleUpdatePermitions($event, data)"
                    />
                  </template>
                </b-table>
              </b-overlay>
            </b-col>
          </b-row>
          <!--  -->
        </b-card>
      </b-col>
    </b-row>

  </section>

</template>

<script>

// import axios from '@axios'
import { mapGetters } from 'vuex'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref } from '@vue/composition-api'

import vSelect from 'vue-select'

import {
  BRow, BCol, BFormGroup, BForm, BCard, BTable, BCardTitle, BCardHeader, BFormCheckbox, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'ProfileList',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    // BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BCardTitle,
    BCardHeader,
    BFormCheckbox,
    BButton,
    vSelect,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BOverlay,
  },
  data() {
    return {
      profileName: '',
      required,
      selected: '',
      sortDesc: false,
      sortBy: 'featureName',
      // option: [{ name: 'Admin' }, { name: 'Analista' }],
      profileList: [],
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  watch: {
    selected() {
      this.resolveSelectedProfile()
    },
  },
  mounted() {
    localize('pt_BR')

    this.fetchProfileList()
  },
  setup() {
    // itens do select de perfil
    const optionsProfileSelect = ref([])
    const qtdUserByProfile = ref(0)
    const permissionsData = ref([])

    const permissionDataToUpdate = ref([])

    const permissionsChecked = ref([])

    const tableColumns = [
      { key: 'featureName', label: 'Funcionalidade', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'read', label: 'Ver', sortable: false },
    ]

    // const prop = ref(permissionsData)

    function handleUpdatePermitions(event, dataPermitions) {
      const permitionObject = {
        // funcionalidades: dataPermitions.item.funcionalidades,
        featureName: dataPermitions.item.featureName,
        featureAlias: dataPermitions.item.featureAlias,
        edit: false,
        read: false,
      }

      const permitionTemp = permissionDataToUpdate.value.filter(permition => permition.featureName === dataPermitions.item.featureName)[0]
      const permitionTempDiff = permissionDataToUpdate.value.filter(permition => permition.featureName !== dataPermitions.item.featureName)

      const newValue = event
      const canChangeIndividual = (dataPermitions.field.key === 'read' && newValue) || (dataPermitions.field.key === 'edit' && !newValue)

      if (permitionTemp) {
        if (canChangeIndividual) {
          permitionTemp[dataPermitions.field.key] = newValue
          permissionDataToUpdate.value = [...permitionTempDiff, permitionTemp]
        } else {
          permitionTemp.edit = newValue
          permitionTemp.read = newValue
        }
      } else {
        permitionObject[dataPermitions.field.key] = newValue
        permissionDataToUpdate.value.push(permitionObject)
      }
    }

    // fetch lista de profiles
    function fetchProfileList() {
      return new Promise((resolve, reject) => {
        // usando a api via jwt
        useJwt.listProfile()
          .then(response => {
            if (response.data.result.length === 0) {
              this.$swal({
                title: 'Nenhum perfil cadastrado',
                text: 'Sua conta ainda não possui nenhum perfil cadastrado, crie um perfil para prosseguir !',
                confirmButtonText: 'Vamos lá!',
                timer: 4000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(() => this.$router.push({ name: 'new-profile' }))
              return resolve(response)
            }

            this.profileList = response.data.result
            this.selected = { name: response.data.result[0].name, id: response.data.result[0].id }
            optionsProfileSelect.value = response.data.result.map(profile => ({ name: profile.name, id: profile.id }))
            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao tentar listar os perfis. Tente novamente',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            // this.$router.push({ name: 'new-profile' })
            reject(error)
          })
      })
    }

    function resolveSelectedProfile() {
      const selectedProfileName = this.selected.name
      qtdUserByProfile.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].usersAssociated
      permissionsData.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles

      permissionDataToUpdate.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles
    }

    function verifyCheckedPermissions(permissionObject) {
      if (permissionObject.edit === true || permissionObject.read === true) {
        this.permissionsChecked.push(permissionObject)
      }
    }

    function updateProfilePermissions() {
      //
      this.permissionDataToUpdate.filter(this.verifyCheckedPermissions)

      if (this.permissionsChecked.length >= 1) {
        //
        return new Promise((resolve, reject) => {
          useJwt.updateProfile({
            name: this.selected.name,
            featuresProfiles: permissionDataToUpdate.value,
            id: this.selected.id,
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Perfil atualizado com sucesso!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              // chamada para atualização da lista de perfil
              this.fetchProfileList()
              resolve(response)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ocorreu um erro ao atualizar o perfil. Tente novamente',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                },
              })
              reject(error)
            })
        })
        //
      }

      return this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Opss...',
          text: 'Você deve adicionar pelo menos uma funcionalidade para o perfil.',
          icon: 'AlertOctagonIcon',
          variant: 'danger',
        },
      })
    }

    function deleteProfile() {
      this.$swal({
        title: 'Tem certeza que deseja excluir este perfil?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
          return new Promise((resolve, reject) => {
            useJwt.deleteProfile({ id: this.selected.id })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Perfil excluído com sucesso!',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.fetchProfileList()
                resolve(response)
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ocorreu um erro...',
                    text: error.response.data.errors[0],
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                  },
                })
                reject(error)
              })
          })
        }
        return result
      })
    }

    return {
      permissionsData,
      tableColumns,
      // prop,
      permissionsChecked,
      handleUpdatePermitions,
      fetchProfileList,
      optionsProfileSelect,
      resolveSelectedProfile,
      qtdUserByProfile,
      permissionDataToUpdate,
      updateProfilePermissions,
      verifyCheckedPermissions,
      deleteProfile,
    }
  },
  methods: {
    goToNewProfile() {
      this.$router.push({ name: 'new-profile' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
